import AlphaHelpers from '@nematis/wp-alpha/assets/js/abstracts/classes/alpha-helpers'

import {
  gsap
} from 'gsap'
import {
  ScrollTrigger
} from 'gsap/ScrollTrigger'

gsap.registerPlugin(ScrollTrigger)
const staggerVal = 0.2
const durationVal = 1.7

const mm = gsap.matchMedia()

document.addEventListener('DOMContentLoaded', () => {
  const gsapElements = AlphaHelpers.smartQuerySelectorAll('[data-gsap]')
  gsapElements.forEach((gsapEl) => {
    const from = AlphaHelpers.getParsedJsonAttribute(gsapEl, 'data-gsap-from')
    const to = AlphaHelpers.getParsedJsonAttribute(gsapEl, 'data-gsap-to')

    if (from && to) {
      gsap.fromTo(gsapEl, from, to)
    } else if (from) {
      gsap.from(gsapEl, from)
    } else if (to) {
      gsap.to(gsapEl, to)
    }
  })
})


document.addEventListener('DOMContentLoaded', () => {
  initGlobalsAnimations()
  // wordsShadow()
})


function initGlobalsAnimations() {
  /*
 *Bloc intro

  mm.add('(min-width: 1200px)', () => {
    gsap.fromTo('.title-block', {
      xPercent: 20
    }, {
      xPercent: 15,
      ease: 'none',
      scrollTrigger: {
        trigger: '.first-block',
        scrub: 1
      }
    })
  })
  */


  // Bloc 1

  mm.add('(min-width: 1200px)', () => {
    gsap.fromTo('.first-block', {
      x: -100
    }, {
      x: 0,
      ease: 'none',
      scrollTrigger: {
        trigger: '.first-block',
        scrub: 1
      }
    })
  })

  mm.add('(min-width: 1200px)', () => {
    gsap.fromTo('.heading_media_text_1', {
      scale: 0.8
    }, {
      scale: 1,
      ease: 'none',
      scrollTrigger: {
        trigger: '.heading_media_text_1',
        scrub: 1
      }
    })
  })


  mm.add('(max-width: 1199px)', () => {
    gsap.fromTo('.heading_media_text_1', {
      opacity: 0
    }, {
      opacity: 1,
      ease: 'none',
      scrollTrigger: {
        trigger: '.heading_media_text_1',
        start: 'top 90%', // the default values
        end: 'top 60%',
        scrub: 1
      }
    })
  })

  mm.add('(max-width: 1199px)', () => {
    gsap.fromTo('.img-first-block', {
      scale: 1
    }, {
      scale: 1.5,
      ease: 'none',
      scrollTrigger: {
        trigger: '.first-block',
        scrub: 1
      }
    })
  })

  // Bloc 2

  mm.add('(min-width: 1200px)', () => {
    gsap.fromTo('.second-block', {
      x: 100
    }, {
      x: 0,
      ease: 'none',
      scrollTrigger: {
        trigger: '.second-block',
        scrub: 1
      }
    })
  })

  mm.add('(min-width: 1200px)', () => {
    gsap.fromTo('.heading_media_text_2', {
      scale: 1
    }, {
      scale: 0.8,
      ease: 'none',
      scrollTrigger: {
        trigger: '.heading_media_text_2',
        scrub: 1
      }
    })
  })

  mm.add('(max-width: 1199px)', () => {
    gsap.fromTo('.heading_media_text_2', {
      opacity: 0
    }, {
      opacity: 1,
      ease: 'none',
      scrollTrigger: {
        trigger: '.heading_media_text_2',
        start: 'top 90%', // the default values
        end: 'top 60%',
        scrub: 1
      }
    })
  })

  mm.add('(max-width: 1199px)', () => {
    gsap.fromTo('.img-second-block', {
      scale: 1
    }, {
      scale: 1.5,
      ease: 'none',
      scrollTrigger: {
        trigger: '.second-block',
        scrub: 1
      }
    })
  })

  // Bloc 3

  mm.add('(min-width: 1200px)', () => {
    gsap.fromTo('.third-block', {
      x: -100
    }, {
      x: 0,
      ease: 'none',
      scrollTrigger: {
        trigger: '.second-block',
        scrub: 1
      }
    })
  })

  mm.add('(min-width: 1200px)', () => {
    gsap.fromTo('.heading_media_text_3', {
      scale: 0.8
      // opacity: 0.25
    }, {
      scale: 1,
      // opacity: 1,
      ease: 'none',
      scrollTrigger: {
        trigger: '.heading_media_text_3',
        scrub: 1
      }
    })
  })

  mm.add('(max-width: 1199px)', () => {
    gsap.fromTo('.heading_media_text_3', {
      opacity: 0
    }, {
      opacity: 1,
      ease: 'none',
      scrollTrigger: {
        trigger: '.heading_media_text_3',
        start: 'top 90%', // the default values
        end: 'top 60%',
        scrub: 1
      }
    })
  })

  mm.add('(max-width: 1199px)', () => {
    gsap.fromTo('.img-third-block', {
      scale: 1
    }, {
      scale: 1.5,
      ease: 'none',
      scrollTrigger: {
        trigger: '.third-block',
        scrub: 1
      }
    })
  })

  // Bloc reinsurance

  gsap.from('.text-heading-reinsurance', {
    opacity: 0,
    ease: 'none',
    scrollTrigger: {
      trigger: '.home-reinsurance',
      start: 'top bottom', // the default values
      end: 'top 85%',
      scrub: 1
    }
  })

  // Bloc Video

  mm.add('(min-width: 1200px)', () => {
    gsap.fromTo('.heading_media_video', {
      scale: 0.8
    }, {
      scale: 1,
      ease: 'none',
      scrollTrigger: {
        trigger: '.heading_media_video',
        scrub: 1
      }
    })
  })


  mm.add('(max-width: 1199px)', () => {
    gsap.fromTo('.heading_media_video', {
      opacity: 0
    }, {
      opacity: 1,
      ease: 'none',
      scrollTrigger: {
        trigger: '.heading_media_video',
        start: 'top 90%', // the default values
        end: 'top 60%',
        scrub: 1
      }
    })
  })

  // Bloc Gallery

  gsap.from('.gallery-block-text', {
    scale: 0.5,
    duration: 1,
    ease: 'none',
    scrollTrigger: {
      trigger: '.gallery-block-text',
      start: 'top bottom', // the default values
      end: 'top center',
      scrub: 1
    }
  })

  // gsap.timeline()
  //   .delay(1)

  /* const homeReinsuranceEl = document.querySelector('.animate-text-shadow-wrapper')
  if (homeReinsuranceEl) {
    animateTextShadow(homeReinsuranceEl)
  }*/

  const elements = document.querySelectorAll('.animate-text-shadow-wrapper')
  elements.forEach((element) => animateTextShadow(element))

  function animateTextShadow(element) {
    const elements = element.getElementsByClassName('animate-text-shadow')

    ScrollTrigger.create({
      trigger: element,
      start: 'top 100%', // when the top of the trigger hits 50% of the way through the view port (i.e. 50% down the screen)
      onEnter: () => {
        // Text forms from shadow
        gsap.to(elements, {
          opacity: 1,
          scale: 1,
          ease: 'elastic.out(1.2, 0.2)',
          stagger: staggerVal
        })
        let tl
        tl = gsap.timeline()
        tl.set(elements, {
          opacity: 1,
          perspective: 400
        }, 'start')
        tl.fromTo(elements, {
          textShadow: '0px 0px 30px #FFF',
          color: 'rgba(0,0,0,0)',
          duration: 0.5,
          stagger: 0.1
        },
        {
          textShadow: '0px 0px 20px #000',
          color: 'rgba(0,0,0,0)',
          duration: 1.5,
          ease: 'power2.in',
          stagger: 0.1
        },
        'start')
          .to(elements,
            {
              textShadow: '0px 0px 0px #000',
              duration: 0.5,
              ease: 'power2.Out',
              stagger: 0.1
            }, 'start+=1')
          .to(elements,
            {
              color: 'rgba(0,0,0,1)',
              duration: 0.5,
              ease: 'power2.inOut',
              stagger: 0.1
            }, 'start+=1.5')
      }
    })
  }
}
